.react-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    color: white;
    position: relative;

    img {
        height: 100vh;
    }

    &--main {
        font-size: 50px;
        font-weight: bold;
        position: absolute;
        margin-top: 30px;
        color: red;

        p {
            text-align: center;
        }
    }

    &--message {
        font-size: 20px;
        color: #ED6C02;
        font-weight: bold;
    }

    &--contact {
        font-size: 20px;
        margin-top: 20px;
    }

    &--btn {
        button {
            border: none;
            border-radius: 20px;
            font-size: 20px;
            padding: 5px 40px;
            background: #c0ffce;
        }
    }
}