.MuiTableBody-root {
    display: table-footer-group !important
}

.clicks-table {
    .MuiTableRow-head {
        th:last-child {
            width: 100px !important;
            svg {
                display: none;
            }
        }

        th {
            div {
                div {
                    span {
                        color: black !important;
                    }
                }

                span {
                    color: #bfbfbf !important;
                }
            }
        }
    }

    .MuiTableRow-root {
        .MuiCheckbox-root {
            .MuiIconButton-label {
                color: #5383ff;
            }

            svg {
                width: 25px;
                height: 25px;
            }
        }
    }
}

.generate-table {
    .MuiTableRow-head {
        th{
            // width: 50px !important;
            svg {
                display: none;
            }
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
    }
    .MuiTableBody-root {
        tr {
            td:last-child {
                div {
                    justify-content: flex-start;
                }
            }
        }
    }
    .copy-clipboard {
        align-items: center;
        background-color: gainsboro;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: .25rem;
        box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        display: inline-flex;
        font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 10px;
        font-weight: 600;
        justify-content: center;
        line-height: 1.25;
        margin: 0;
        min-height: 10px;
        padding: 5px;
        position: relative;
        text-decoration: none;
        transition: all 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: baseline;
        width: auto;
    }

    .copy-clipboard:hover,
    .copy-clipboard:focus {
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
        color: rgba(0, 0, 0, 0.65);
    }

    .copy-clipboard:hover {
        transform: translateY(-1px);
    }

    .copy-clipboard:active {
        background-color: #F0F0F1;
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
        color: rgba(0, 0, 0, 0.65);
        transform: translateY(0);
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }
}